<template>
  <ion-page>
    <ion-content :fullscreen="true" :style="{'--background': $setting.bg_color || '#000000'}">
      <div class="landing" :style="{'background': `url(${$setting.landing_cover}) no-repeat center center fixed`}">
        <!-- <img :src="$setting.leanding_cover" /> -->
        <ion-button mode="ios" shape="round" expand="block" color="success" class="btn mt-10 green" @click="$router.push('/')">Mulai Game</ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonPage,
    IonButton
  },
  data () {
    return {
      email: null,
      error: false
    }
  },
  computed: {
    ...mapGetters({
      $setting: 'setting/getSetting'
    })
  },
  mounted() {
    let that = this
    setTimeout(function(){
      that.$router.push('/')
    }, 3000);
  },
});
</script>

<style lang="scss">
.bg-black {
  --background: #000000;
}
.landing {
  // padding: 1rem;
  height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  ion-button {
    position: absolute;
    bottom: 2rem;
    left: 4rem;
    right: 4rem;
  }
  img {
    width: 100%;
    object-fit: cover;
  }
}
.btn.button-round.green {
  --box-shadow: none;
}
</style>
